<template>
  <div class="vap-page">
    <Loading
      :active.sync="isLoading"
      :is-full-page="false"
      loader="dots"
      :color="loaderColor"
      :background-color="loaderBackgroundColor"
    />
    <div class="vap-page-header">
      <h1>Two-Factor Authentication</h1>
      <div class="btns-container">
        <VasionButton
          id="btnSave"
          class="last-btn"
          classProp="primary"
          @vasionButtonClicked="saveConfig"
        >
          Save
        </VasionButton>
      </div>
    </div>
    <div class="vap-page-content">
      <div class="smsInnerDiv">
        <div>
          <span>A Twilio Account is required to send SMS Text Messages.</span>
          <div>
            <a class="vasion-cursor" href="https://www.twilio.com/try-twilio" target="_blank"><span class="link">Please visit Twilio to create an Account.</span></a>
          </div>
          <div class="section">
            <div class="bottom-buffer">
              <VasionInput
                id="smsAccountSID"
                v-model="accountSID"
                class="input-style"
                inputType="top-white"
                name="smsAccountSID"
                :title="'Twilio Account SID'"
              />
            </div>
            <div class="bottom-buffer">
              <VasionInput
                id="smsAuthToken"
                v-model="authToken"
                class="input-style"
                inputType="top-white"
                name="smsAuthToken"
                type="password"
                :title="'Twilio Auth Token'"
              />
            </div>
            <div class="bottom-buffer">
              <VasionInput
                id="smsFromPhoneNumber"
                v-model="fromPhoneNumber"
                class="input-style"
                inputType="top-white"
                name="smsFromPhoneNumber"
                :title="'From Phone Number'"
                type="tel"
              />
            </div>
          </div>
          <div class="collapse-section-header">
            <VasionButton
              name="collapse-section-button"
              class="collapse-section-button"
              :icon="showTestMessageSection ? 'VasionArrowDropDownIcon' : 'VasionArrowDropDownRight16Icon'"
              title="Collapse Section"
              @vasionButtonClicked="showTestMessageSection = !showTestMessageSection"
            />
            <label class="vasion-page-subheader">Test Twilio Message</label>
          </div>
          <div v-show="showTestMessageSection" class="section">
            <VasionInput
              id="smsSendToNumber"
              v-model="sendTestNumber"
              :style="{ marginLeft: '8px' }"
              class="input-style"
              inputType="top-white"
              name="smsSendToNumber"
              :title="'To Phone Number'"
              type="tel"
            />
            <VasionButton
              id="btnSendTestMessage"
              :classProp="'primary'"
              :isDisabled="sendTestNumber === ''"
              @vasionButtonClicked="sendTestMessage"
            >
              Send Test Message
            </VasionButton>
          </div>
        </div>
      </div>
    </div>
    <VasionSnackbar
      id="two-factor-auth-snack"
      :showSnackbarBool.sync="showSnackbar"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'

export default {
  name: 'TwoFactorAuth',
  components: {
    Loading,
  },
  data: function () {
    return {
      accountSID: '',
      authToken: '',
      fromPhoneNumber: '',
      isLoading: false,
      loaderBackgroundColor: loaderBackgroundColor,
      loaderColor: loaderColor,
      sendTestNumber: '',
      showSnackbar: false,
      showSnackbarBool: false,
      showTestMessageSection: false,
      snackbarImage: false,
      snackbarSubTitle: '',
      snackbarTitle: '',
    }
  },
  computed: {
  },
  async created() {
    await this.$store.dispatch('mainViews/getSmsConfig')
    if (this.$store.state.mainViews.smsConfig) {
      this.accountSID = this.$store.state.mainViews.smsConfig.accountSID
      this.authToken = this.$store.state.mainViews.smsConfig.authToken
      this.fromPhoneNumber = this.$store.state.mainViews.smsConfig.fromPhoneNumber
    }
  },
  methods: {
    buildPayload() {
       const payload = {
        accountSID: this.accountSID,
        authToken: this.authToken,
        fromPhoneNumber: this.fromPhoneNumber,
      }

      return payload
    },
    hideSnackbar() { this.showSnackbar = false },
    async saveConfig() {
      if (!this.validateData()) {
        this.showSnackbar = true
        return
      }

      const payload = this.buildPayload()
      this.isLoading = true
      const result = await this.$store.dispatch('mainViews/saveSmsConfig', payload)

      this.snackbarSubTitle = result ? 'Settings saved' : 'Error saving data'
      this.snackbarImage = true
      this.isLoading = false
      this.showSnackbar = true
    },
    async sendTestMessage() {
      if (!this.validateData()) {
        return
      }

      if (!this.sendTestNumber) {
        this.snackbarSubTitle = 'Please enter a Send to Number'
        this.snackbarImage = false
        this.showSnackbar = true
        return
      }

      const payload = {
        sendToNumber: this.sendTestNumber,
        twilioSettings: this.buildPayload(),
      }

      this.snackbarSubTitle = 'Test Message Sent!'
      this.snackbarImage = true
      this.isLoading = true
      const response = await this.$store.dispatch('mainViews/sendTestTextMessage', payload)
      if (!response || !response.data || !response.data.Value || response.data.Value === 'False') {
        this.snackbarSubTitle = 'The message failed to send, please try again.'
        this.snackbarImage = false
      }

      this.isLoading = false
      this.showSnackbar = true
    },
    validateData() {
      this.snackbarSubTitle = ''
      this.snackbarImage = false

      if (!this.accountSID) {
        this.snackbarSubTitle = 'Please enter the Account SID.'
        return false
      }

      if (!this.authToken) {
        this.snackbarSubTitle = 'Please enter the Auth Token'
        return false
      }

      if (!this.fromPhoneNumber) {
        this.snackbarSubTitle = 'Please enter the From Phone Number'
        return false
      }

      this.snackbarImage = false
      return true
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';
  @import '@/assets/css/light-theme-system-palette.scss';
  .bottom-buffer {
    padding-bottom: 15px;
  }

  .smsSaveButtonDiv {
    text-align: right;
  }

  .smsMainDiv {
    background-color: white;
  }

  .smsInnerDiv {
    width: 400px;
    margin: 20px;
  }

  .title-div {
    margin: 20px;
  }

  .header {
    width: 99%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .collapse-section-button {
    width: 32px;
    height: 32px;
    border-radius: 8px;
    background-color: $grey-75;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .collapse-section-button:hover {
    border-radius: 8px;
  }

  .collapse-section-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 32px;
  }

  .section {
    margin-top: 24px;
  }

  .link {
    color: $secondary;
  }

  .link:hover {
    color: $tertiary;
  }

  a:hover {
    text-decoration: none;
  }

</style>
